export default {

  DAY_TYPES: [
    { value: 'DOCK', text: 'À quai' },
    { value: 'SHUTTLE', text: 'Navette' },
    { value: 'NIGHT_AT_DOCK', text: 'Nuit à quai' },
    { value: 'SAILING', text: 'Navigation' },
    { value: 'BOARDING', text: 'Embarquement' },
    { value: 'LANDING', text: 'Débarquement' }
  ],

}


