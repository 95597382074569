<template>
  <group-itinerary
    v-if="group"
    :group-id="group.id"
    :current-group="group"
    :current-itinerary="itineraries"
  />
</template>

<script>
import GroupItinerary from "@/views/groups/group/GroupItinerary/GroupItinerary.vue";

export default {
  components: {
    GroupItinerary,
  },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    itineraries() {
      return this.$store.state["app-groups"]?.itineraries ?? [];
    },
  },
};
</script>
