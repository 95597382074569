<template>
  <text-input-field-with-validation
    :validation-rules="'required'"
    v-model="innerValue"
    :name="name"
    :label="label"
    :label-for="labelFor"
    :placeholder="placeholder"
    @input="(input)=>$emit('input',input)"
  />
</template>

<script>
import TextInputFieldWithValidation from "@/shared/components/TextInputFieldWithValidation";

export default {
  name: 'required-text-input-field',
  components: {
    TextInputFieldWithValidation,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String | undefined,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    labelFor: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    value(newVal) {
      this.innerValue = newVal === undefined ? '' : newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
