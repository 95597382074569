<template>
<div>
  <validation-provider #default="{errors, valid = null}" :rules="validationRules" :name="name">
    <b-form-group :label="label" :label-for="labelFor">
      <b-form-input
        v-model="innerValue"
        :type="type"
        :placeholder="placeholder"
        :state="errors[0] ? false : (valid ? true : null)"
        trim
        @input="(input)=>$emit('input',input)"
      />
      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>

</div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {BForm, BFormGroup, BFormInput, BFormInvalidFeedback} from "bootstrap-vue";

export default {
  name: 'text-input-field-with-validation',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    labelFor: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    validationRules: {
      type: [Object, String],
      required: true,
    },
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
